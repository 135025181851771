<!--
  eslint-disable vue/no-v-html

  清單模組：績效
-->

<template>
  <div v-if="list && list.length > 0">
    <div v-if="date" class="date-wrap">
      <span>觀察期間：{{ date.observePeriod[0] | timeformat('YYYY-MM') }} ~ {{ date.observePeriod[1] | timeformat('YYYY-MM') }} </span>
      <span>選取範圍：{{ date.selectedRange[0] }} ~ {{ date.selectedRange[1] }} </span>
    </div>
    <div v-if="options.sort" class="sort-wrap mobile-only">
      <SortListDropdown v-bind="{sortTitles}" :sortBy.sync="current.sortBy" />
    </div>

    <div ref="list" class="list">
      <ViewportCondition value="> md">
        <div class="list-head">
          <div class="row head-row">
            <template v-if="options.sort">
              <div class="grid g-title" />
              <Scroll ref="head" class="column-group" @ps-scroll-x.native="updateScroll">
                <div v-for="title in titles" :key="title.id" class="grid">
                  <SortableFieldName v-model="current.sortBy" :name="title.id">
                    <span class="nowrap" v-html="title.name" />
                  </SortableFieldName>
                </div>
              </Scroll>
            </template>
            <template v-else>
              <div class="grid g-title" />
              <Scroll ref="head" class="column-group" @ps-scroll-x.native="updateScroll">
                <div v-for="title in titles" :key="title.id" class="grid" v-html="title.name" />
              </Scroll>
            </template>
            <div v-if="options.remove && list.length > 1" class="grid actions" >
              刪除
            </div>
            <!-- Slot: 欄位擴充 -->
            <slot name="head-column-after" />
          </div>
        </div>
        <div v-if="list" class="list-body">
          <template v-for="(item, index) in fundsSorted">
            <div :key="`body-row-group-${index}`" class="body-row-group" :class="item.rowClasses"
                 @mouseenter="$bus.$emit('chart:entry-active', item.id, moduleName)" @mouseleave="$bus.$emit('chart:entry-idle', item.id, moduleName)"
            >
              <div class="row body-row" :style="isActive(item.id, item.backgroundColor)">
                <div class="grid g-title">
                  <FundName
                    v-if="fundItems.includes(item.id) && !singleFund"
                    :color="colors[item.itemId]"
                    :fundId="item.id"
                    :fundName="item.label"
                  />
                  <template v-else>
                    <span class="color-line" :style="{backgroundColor: colors[item.itemId]}" />
                    <span>{{ item.label }}</span>
                  </template>
                </div>

                <Scroll ref="info" class="column-group" @ps-scroll-x.native="updateScroll">
                  <div class="grid" :class="symbolClass(item.value)">
                    {{ item.value | percentage(1) }}
                  </div>
                  <template v-if="item.fund && date && date.observePeriod[0] < item.fund.launch_date">
                    <!-- 期間起始日早於基金發行日 -->
                    <div v-for="n in 3" :key="n" class="grid">
                      <span>–</span>
                      <i class="icon-info-small" title="本基金於期初尚未成立，故無法計算年化數據。" />
                    </div>
                  </template>
                  <template v-else>
                    <div class="grid" :class="symbolClass(item.perf)">
                      {{ item.perf | percentage(1) }}
                    </div>
                    <div class="grid" :class="symbolClass(item.vol)">
                      {{ item.vol | percentage(1) }}
                    </div>
                    <div class="grid" :class="symbolClass(item.sharp)">
                      {{ item.sharp | round(3) }}
                    </div>
                  </template>
                  <div v-for="column in extraColumns" :key="column.id" class="grid" :class="symbolClass(item[column.id])">
                    {{ column.formatter ? column.formatter(item[column.id]) : item[column.id] }}
                  </div>
                </Scroll>

                <div v-if="options.remove && list.length > 1" class="grid actions">
                  <div v-if="!lockedItems.includes(item)" class="icon-button third" @click="remove(item.id)">
                    <i class="icon-delete" aria-hidden="true" />
                  </div>
                </div>

                <!-- Slot: 欄位擴充 -->
                <slot name="body-column-after" v-bind="item" />
              </div>
              <!-- 每一列下方擴充的 Slots -->
              <div v-if="$scopedSlots['item-after']" :key="`extra-row-item-${index}`" class="row body-row extra" :style="isActive(item.id, item.backgroundColor)">
                <slot name="item-after" v-bind="item" />
              </div>
              <div v-if="$scopedSlots['fund-after']" :key="`extra-row-fund-${index}`" class="row body-row extra" :style="isActive(item.id, item.backgroundColor)">
                <slot v-if="isFundItem(item)" name="fund-after" v-bind="item" />
              </div>
            </div>
          </template>
        </div>
      </ViewportCondition>

      <ViewportCondition value="< md">
        <div class="list-head" :class="{'status-fixed': fixedHead}">
          <div class="fixed-area">
            <Scroll ref="head" @ps-scroll-x.native="updateScroll">
              <div class="row head-row">
                <template v-for="title in titles">
                  <div v-if="title.id != 'score'" :key="title.id" class="grid">
                    <span class="nowrap" v-html="title.name" />
                  </div>
                </template>
              </div>
            </Scroll>
          </div>
        </div>
        <div class="list-body">
          <template v-for="(item, index) in fundsSorted">
            <div :key="`body-row-group-${index}`" class="body-row-group" :class="item.rowClasses">
              <div class="row body-row">
                <div class="row-name">
                  <div class="sector-content">
                    <FundName
                      v-if="fundItems.includes(item.id) && !singleFund"
                      :color="colors[item.itemId]"
                      :fundId="item.id"
                      :fundName="item.label"
                    />
                    <template v-else>
                      <span class="color-line" :style="{backgroundColor: colors[item.itemId]}" />
                      <span>{{ item.label }}</span>
                    </template>
                  </div>
                  <div v-if="options.remove && list.length > 1" class="actions">
                    <div v-if="!lockedItems.includes(item)" class="icon-button third" @click="remove(item.id)">
                      <i class="icon-delete" aria-hidden="true" />
                    </div>
                  </div>
                  <!-- Slot: 欄位擴充 -->
                  <slot name="body-column-after" v-bind="item" />
                </div>
                <Scroll ref="info" @ps-scroll-x.native="updateScroll">
                  <div class="other-info">
                    <div class="grid" :class="symbolClass(item.value)">
                      {{ item.value | percentage(1) }}
                    </div>
                    <template v-if="item.fund && date && date.observePeriod[0] < item.fund.launch_date">
                      <!-- 期間起始日早於基金發行日 -->
                      <div v-for="n in 3" :key="n" class="grid">
                        -<i class="icon-info-small" title="本基金於期初尚未成立，故無法計算年化數據。" />
                      </div>
                    </template>
                    <template v-else>
                      <div class="grid" :class="symbolClass(item.perf)">
                        {{ item.perf | percentage(1) }}
                      </div>
                      <div class="grid" :class="symbolClass(item.vol)">
                        {{ item.vol | percentage(1) }}
                      </div>
                      <div class="grid" :class="symbolClass(item.sharp)">
                        {{ item.sharp | round(3) }}
                      </div>
                    </template>
                    <div v-for="column in extraColumns" :key="column.id" class="grid" :class="symbolClass(item[column.id])">
                      {{ column.formatter ? column.formatter(item[column.id]) : item[column.id] }}
                    </div>
                  </div>
                </Scroll>
              </div>
              <!-- 每一列下方擴充的 Slots -->
              <div v-if="$scopedSlots['item-after']" :key="`extra-row-item-${index}`" class="row body-row extra">
                <slot name="item-after" v-bind="item" />
              </div>
              <div v-if="$scopedSlots['fund-after']" :key="`extra-row-fund-${index}`" class="row body-row extra">
                <slot v-if="isFundItem(item)" name="fund-after" v-bind="item" />
              </div>
            </div>
          </template>
        </div>
      </ViewportCondition>
      <!-- Slot: 列表後 -->
      <slot name="list-after" />
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
/* eslint-disable eqeqeq */
import MixinScrollList from 'modules/components/fund/MixinScrollList'
import MixinSortList from 'modules/components/fund/MixinSortList'

import SortableFieldName from 'modules/ui/table/SortableFieldName'
import SortListDropdown from 'modules/components/fund/SortListDropdown.vue'
import ViewportCondition from 'modules/misc/ViewportCondition.vue'
import Scroll from 'modules/ui/scroll'
import FundName from 'modules/components/fund/FundName'

export default {
  components: {
    SortableFieldName,
    SortListDropdown,
    ViewportCondition,
    Scroll,
    FundName,
  },
  filters: {
    max: function(value) {
      return Math.max.apply(this, value)
    },
    min: function(value) {
      return Math.min.apply(this, value)
    },
    abs: function(value) {
      return Math.abs(value)
    }
  },
  mixins: [
    MixinScrollList,
    MixinSortList,
  ],
  props: {
    moduleName: {
      type: String,
      default: 'defaultModule'
    },
    list: {
      type: Array,
      default: () => [],
    },
    date: Object,
    currency: String,
    options: {
      type: Object,
      default: () => ({
        remove: false,
        sort: false,
        extraColumns: []
      })
    },
    fundColors: Object,
    singleFund: {
      type: Boolean,
      default: () => false,
    },
    extraColumns: {
      type: Array,
      default: () => ([])
    },
    /**
     *不可刪除的項目
     */
    lockedItems: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      current: {
        activeId: null,
        sortBy: [],
      },
      titles: [
        { id: 'value', name: '選取期間報酬率' },
        { id: 'perf', name: '期間平均年報酬率' },
        { id: 'vol', name: '期間年化波動率' },
        { id: 'sharp', name: '期間夏普值' },
        ...this.extraColumns
      ],
      // TODO 必須動態取得
      fundItems: ['GCMF', 'SOF', 'UGWAF'],
    }
  },
  computed: {
    colors() {
      return this.list.reduce((map, f) => {
        map[f.itemId] = f.color || this.fundColors[f.itemId]
        return map
      }, {})
    },
    sortTitles() {
      const options = this.titles.map(title => {
        return {
          ...title,
          name: `${title.name}，高→低`
        }
      })
      options.unshift({ id: 'default', name: '預設排序' })
      return options
    },
    fundsSorted() {
      return this.getSorted(this.list)
    },
  },
  created() {
    this.$bus.$on('chart:entry-active', (id, fromModule) => { if (fromModule == this.moduleName) { this.current.activeId = id } })
    this.$bus.$on('chart:entry-idle', (id, fromModule) => { if (fromModule == this.moduleName) { this.current.activeId = null } })
  },
  methods: {
    isActive(lineId, bgColor) {
      return this.current.activeId == lineId ? `background-color: ${bgColor}` : ''
    },
    isFundItem(item) {
      return item.id?.indexOf('fund-') == 0
    },
    remove(id) {
      this.$emit('remove', id)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~modules/components/fund/scrollList.styl'
.list
  position relative
  border-top 1px solid #ededed
  .grid
    width 175px
    &:not(.g-title)
      max-width 150px
    &.g-title
      display flex
      align-items center
      flex 1 0 calc(100% - 660px)
      min-width 200px
      text-align left
    &.actions
      width 80px
      > div
        margin auto
.column-group
  @media $desktop
    .grid
      text-align right
      flex 1 0 175px

.date-wrap
  padding 0 1.5rem 1rem
  color $gray2
  +over(md)
    span + span
      margin-left 1.5rem
  +under(md)
    span
      display block
.sort-wrap
  border-top 1px solid #ededed
  padding 1rem 1.5rem

.price-ccy
  margin-left .5rem
  &:before
    content "("
  &:after
    content ")"
.preview-feature
  vertical-align top
  display inline
  white-space nowrap
  color #FF895A
  margin-left .5em

+under(md)
  .body-row
    .row-name, .sector-content
      display flex
      align-items center
      flex-grow 1
    .actions
      margin-left auto
      padding-left .5rem
      display flex
</style>
