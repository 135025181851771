<template>
  <FloatPanel ref="panel" class="panel" mobileCompatible v-bind="$attrs" @open="load">
    <template #title>
      基金及指標選取 ({{ current.pickedIds.length }})
      <ViewportCondition value="> md">
        <span v-if="isDisabled" class="warning">
          {{ warningMessage }}
        </span>
        <span v-else-if="isFull" class="warning">
          已達單次比較上限，開始進行比較吧
        </span>
      </ViewportCondition>
    </template>
    <template #right>
      <ViewportCondition value="> md">
        <div class="ui button minor large" @click="leave">
          取消
        </div>
        <div class="ui button major large" :class="{disabled: isDisabled}" @click="submit">
          比較
        </div>
      </ViewportCondition>
    </template>
    <template #body="{is}">
      <div v-if="is.opened">
        <div class="ui container">
          <div class="panel-layout">
            <div class="panel-header">
              基金及指標選取
            </div>
            <div class="multi-sections">
              <div class="column">
                <div class="section">
                  <div class="section__head">
                    UG 基金（需至少選取一支）
                    <div class="head-right-buttons">
                      <div class="ui button minor micro" @click="clearAll(funds)">
                        全部清空
                      </div>
                    </div>
                  </div>
                  <div class="section__body">
                    <div class="option-list">
                      <div v-for="fund in funds" :key="fund.id" class="label-row" :class="{checked: isChecked(fund), disabled: (isFull && !isChecked(fund))}" @click="toggleClick(fund.id)">
                        <UiCheckbox :ref="fund.id" v-model="current.pickedIds" :value="fund.id" :disabled="isFull && !isChecked(fund)">
                          <span class="label-name font-bold">{{ fund.name }}</span>
                        </UiCheckbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="section__head">
                    參考指標
                    <div class="head-right-buttons">
                      <div class="ui button minor micro" @click="clearAll(benchmarks)">
                        全部清空
                      </div>
                    </div>
                  </div>
                  <div class="section__body">
                    <div class="option-list">
                      <div v-for="bench in benchmarks" :key="bench.id" class="label-row" :class="{checked: isChecked(bench), disabled: (isFull && !isChecked(bench))}" @click="toggleClick(bench.id)">
                        <UiCheckbox :ref="bench.id" v-model="current.pickedIds" :value="bench.id" :disabled="isFull && !isChecked(bench)">
                          <span class="label-name font-bold">{{ bench.name }}</span>
                        </UiCheckbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="section">
                  <div class="section__head">
                    台灣熱門基金
                  </div>
                  <template v-for="(group, title) in groupedTaroboFunds">
                    <div :key="`${title}-head`" class="section__head secondary">
                      {{ title }}
                      <div class="head-right-buttons">
                        <div class="ui button minor micro" @click="clearAll(group)">
                          全部清空
                        </div>
                      </div>
                    </div>
                    <div :key="`${title}-body`" class="section__body">
                      <div class="option-list">
                        <div v-for="item in group" :key="item.id" class="label-row" :class="{checked: isChecked(item), disabled: (isFull && !isChecked(item))}" @click="toggleClick(item.id)">
                          <UiCheckbox :ref="item.lipper_id" v-model="current.pickedIds" :value="item.id" :disabled="isFull && !isChecked(item)">
                            <span class="label-name font-bold">{{ item.name }}</span>
                          </UiCheckbox>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewportCondition value="< md">
          <div class="actions">
            <div class="message-area">
              <span v-if="isDisabled" class="warning">
                {{ warningMessage }}
              </span>
              <span v-else-if="isFull" class="warning">
                已達單次比較上限，開始進行比較吧
              </span>
            </div>
            <div class="rule-buttons-group tighter fluid nowrap">
              <div class="ui button fluid minor" @click="leave">
                取消
              </div>
              <div class="ui button fluid major" :class="{disabled: isDisabled}" @click="submit">
                比較
              </div>
            </div>
          </div>
        </ViewportCondition>
      </div>
    </template>
  </FloatPanel>
</template>

<script>
import { PanelImplement } from 'modules/ui/FloatPanel.vue'
import UiCheckbox from 'modules/ui/form/checkbox'
import ViewportCondition from 'modules/misc/ViewportCondition.vue'

// 將 ID 轉為正確的資料格式
function normalizeId(id) {
  if (String(id).match(/^\d+$/)) {
    return +id
  } else {
    return String(id)
  }
}

export default {
  components: {
    UiCheckbox,
    ViewportCondition,
  },
  mixins: [
    PanelImplement,
  ],
  props: {
    funds: {
      type: Array,
      default() {
        return []
      },
    },
    taroboFunds: {
      type: Array,
      default() {
        return []
      },
    },
    benchmarks: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      initialPickedIds: [],
      current: {
        pickedIds: [],
      },
    }
  },
  computed: {
    groupedTaroboFunds() {
      return this.taroboFunds.reduce((hash, fund) => ({ ...hash, [fund.category]: [...(hash[fund.category] || []), fund] }), {})
    },
    isDisabled() {
      return !!this.warningMessage
    },
    isFull() {
      return this.current.pickedIds.length >= 8
    },
    fundIds() {
      if (this.funds.length === 0) return []
      return this.funds.map(f => f.id)
    },
    benchmarkIds() {
      if (this.benchmarks.length === 0) return []
      return this.benchmarks.map(f => f.id)
    },
    hasUgFund() {
      return this.funds.some(fund => this.current.pickedIds.includes(fund.id))
    },
    warningMessage() {
      const messages = []
      if (this.current.pickedIds.length === 1) messages.push('再選 1 個項目進行比較')
      else if (this.current.pickedIds.length < 2) messages.push('至少選 2 個做比較')
      else if (!this.hasUgFund) messages.push('至少選 1 支 UG 基金')
      return messages.join('、')
    }
  },
  watch: {
    selectedIds: {
      immediate: true,
      handler(newVal) {
        this.current.pickedIds = this.initialPickedIds = newVal.map(normalizeId)
      }
    },
  },
  mounted() {
    this.current.pickedIds = this.initialPickedIds = this.selectedIds
  },
  methods: {
    toggleClick (id) {
      const target = this.$refs[`${id}`][0]
      target.onClick()
    },
    reset() {
      this.current.pickedIds = this.initialPickedIds
    },
    leave() {
      this.reset()
      this.close()
    },
    submit() {
      this.$emit('submit', this.current.pickedIds)
      this.close()
    },
    seletedAll(items) {
      const ids = items.map(({ id }) => id)
      ids.forEach(id => {
        if (!this.current.pickedIds.includes(id)) this.current.pickedIds.push(id)
      })
    },
    clearAll(items) {
      const ids = items.map(({ id }) => id)
      ids.forEach(id => {
        const index = this.current.pickedIds.indexOf(id)
        if (index !== -1) this.current.pickedIds.splice(index, 1)
      })
    },
    isChecked(item) {
      return this.current.pickedIds.includes(item.id)
    }
  },
}

</script>

<style lang="stylus" scoped>

boxed()
  box-shadow \
    0 -1px $gray3,
    -1px 0 $gray3

.panel
  user-select none

.warning
  color $red
  font-size 1rem
  margin-left 1rem
  line-height 1

@media $mobile
  .ui.container
    margin 0 !important
    padding 0 !important
  .panel-header
    display none !important

.panel-layout
  background-color #fff
  overflow hidden
  @media $desktop
    border 1px solid #ededed
    box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
    border-radius 21px
  .section-title
    font-size 1.2rem
    line-height 21px
    font-weight bold
    margin-bottom 1.5rem

.panel-header
  boxed()
  line-height 1
  font-size 1.2em
  font-weight bold
  padding (1.71428 / 1.2) * 1em

.ui.grid > .row > .fitted.column
  padding 0

.multi-sections
  display flex
  flex-wrap wrap
  .column
    boxed()
    display flex
    flex-direction column
    flex-basis 50%
    flex-grow 1
    padding-bottom 1em // 圓角 Safe area
    @media $mobile
      flex-basis 100%
  .section
    boxed()
    height 100%
    &__head
      boxed()
      line-height 1
      background-color $gray5
      padding 0.857em 1.5em
      font-weight bold
      display flex
      height 42px
      &.secondary
        font-weight normal
        background-color transparent
        color $gray2
    &__body
      boxed()
      flex-grow 1

.option-list
  line-height 1
  overflow hidden
  .label-row
    padding 1.10715rem 1.5rem
    &, & *
      cursor pointer
    &:hover
      background-color #F0F8FF
    &.checked
      background-color #FEF9F4
  .label-name
    padding-left .5rem
    display inline-block

.head-right-buttons
  margin -0.857em 0
  margin-left auto
  display flex
  align-items center

.actions
  boxed()
  position sticky
  bottom 0
  background #FFF
  padding 0.75rem 1rem
  display flex
  flex-wrap wrap
  > *
    flex-grow 1
  .message-area
    margin-bottom .5em
    .warning
      margin 0

</style>
