<script>
/* eslint-disable vue/require-default-prop */
/* eslint-disable eqeqeq */
import { ChartPerformanceData } from './lib.js'
import Core from 'modules/chart/performance/core'
import API from 'api'

export default {
  mixins: [
    Core
  ],
  props: {
    benchmark: Boolean,
  },
  computed: {
    queryParams() {
      return {
        from: moment(this.modifiedDateStart).startOf('month').format(moment.HTML5_FMT.DATE),
        to: moment(this.current.dateEnd).endOf('month').format(moment.HTML5_FMT.DATE),
        funds: this.fundIds.join(','),
        benchmarks: this.benchmarkIds.join(','),
        currency: 'usd',
      }
    },
    // 未選擇比較標的
    isEmpty() {
      return [...this.fundIds, ...this.benchmarkIds].length === 0
    }
  },
  watch: {
    data: function() {
      this.chart.setData(new ChartPerformanceData(this.data))
      this.chart.dataset.setColors(this.colors)
      this.chart.render()
      this.resetDates()
    },
    queryParams() {
      this.lazyload()
    },
  },
  methods: {
    async load() {
      if (this.isEmpty) {
        return
      }
      const loader = this.createLoadingState()
      const { funds, benchmarks } = await API.chart.perfomance(this.queryParams).then(res => res.data)

      this.data = [
        // 混入 type 欄位
        ...funds.map(entry => ({ ...entry, type: 'fund' })),
        ...benchmarks.map(entry => ({ ...entry, type: 'bench' })),
      ].map((entry) => ({
        ...entry,
        // 圖表資料對應
        label: entry.name,
        data: entry.performance,
        // 表格資料欄位對應
        ann: {
          perf: entry.perf,
          sharp: entry.sharp,
          vol: entry.vol,
        },
      }))

      // 將 dateStart ~ 基金發行日 填滿每個月的空點
      this.data.forEach((line) => {
        const startAt = line.data[0].date
        const append = []
        for (let date = this.dateStart; moment(date).endOf('month').isBefore(startAt); date = moment(date).add(1, 'month')) {
          append.push({
            date: moment(date).endOf('month').format(moment.HTML5_FMT.DATE),
            value: null
          })
        }
        line.data = [
          ...append,
          ...line.data.map(point => {
            // 優先使用 t_date，為對齊的淨值日
            return {
              ...point,
              date: point.t_date || point.date,
            }
          }),
        ]
      })

      loader.finish()
    }
  }
}
</script>
